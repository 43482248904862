/* Copyright © 2016 Kuali, Inc. - All Rights Reserved
 * You may use and modify this code under the terms of the Kuali, Inc.
 * Pre-Release License Agreement. You may not distribute it.
 *
 * You should have received a copy of the Kuali, Inc. Pre-Release License
 * Agreement with this file. If not, please write to license@kuali.co.
 */

.styles__wrapper___3dXtd {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.styles__panel___1vmlO {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #fff;
  border-radius: 0.4rem;
  -webkit-box-shadow: 0 0.1em 0.2em hsla(0, 0%, 0%, 0.15);
          box-shadow: 0 0.1em 0.2em hsla(0, 0%, 0%, 0.15);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 4em;
}

.styles__msg___1tSY5 {
  font-size: 1.5em;
  font-weight: 200;
}

.styles__img___2nVlP {
  max-width: 100%;
  min-width: 110px;
  padding: 2em;
}
